<
<template>
  <div class="app-ecommerce">
    <!-- Add Product -->

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxTwo"
            >
              Publier le produit
            </button>
          </div>

          <button type="submit" class="btn btn-secondary ml-3">Annuler</button>
        </div>
      </div>
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-7">
          <!-- Product Information -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Informations sur le produit</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Titre du produit</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="product.name"
                  placeholder="Product title"
                  name="productTitle"
                  aria-label="Product title"
                />
              </div>
              <div></div>
              <div class="row mb-3">
                <div class="col">
                  <label class="form-label" for="ecommerce-product-sku"
                    >SKU</label
                  >
                  <input
                    class="form-control"
                    v-model="product.sku"
                    placeholder="SKU"
                    name="productSku"
                    aria-label="Product SKU"
                  />
                </div>
              </div>
              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="product.description" />
              </div>
              <div class="mt-2">
                <H5 class="form-label"> Brève description </H5>
                <quill-editor v-model="product.short_description" />
              </div>
              <div class="mt-2">
                <H5 class="form-label">Propriétés</H5>
                <quill-editor v-model="product.Proprietes" />
              </div>
              <div class="mt-2">
                <H5 class="form-label">Avantages</H5>
                <quill-editor v-model="product.Avantages" />
              </div>
              <div class="mt-2">
                <H5 class="form-label">Informations Complémentaires </H5>
                <quill-editor v-model="product.informations" />
              </div>
            </div>
          </div>
          <!-- /Product Information -->
        </div>
        <!-- /Second column -->

        <!-- Second column -->
        <div class="col-12 col-lg-5">
          <!-- Pricing Card -->
          <div class="card mb-4">
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="card-title mb-0">Attachment</h5>
              </div>
              <div class="card-body">
                <input
                  class="form-control"
                  style="margin-bottom: 15px"
                  v-model="product.attachment_name"
                  placeholder="Label Attachment"
                  name="Label Attachment"
                  aria-label="Label Attachment"
                />

                <b-form-file
                  ref="file"
                  @change="uploadPdf"
                  placeholder="Choose a PDF file or drop it here..."
                  drop-placeholder="Drop PDF file here..."
                  accept=".pdf"
                />
              </div>
            </div>
          </div>
          <!-- /Pricing Card -->
          <!-- Organize Card -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Catégories</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechCategory()"
              />
            </div>
            <div class="card-body">
              <div class="mb-3">
                <v-select
                  v-model="product.categories"
                  multiple
                  :options="flattenedCategories"
                  label="formattedName"
                >
                </v-select>
              </div>
            </div>
          </div>
          <!-- Organize Card -->

          <!-- Media -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <b-overlay
                  :show="imageOverlay"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    v-model="imagesUrl"
                    :data-images="imagesUrl"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageAndReturnSrc"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
                <div class="col">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Media -->
          <!-- /Organize Card -->
        </div>
        <!-- /Second column -->
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";

import {
  BTabs,
  BTab,
  BCardText,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  VBTooltip,
  BFormFile,
} from "bootstrap-vue";
import ProductSettingPrice from "./ProductSettings/ProductSettingPrice.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Ripple from "vue-ripple-directive";
import { useCurrencyInput } from "vue-currency-input";
import {
  uploadImageSuccess,
  uploadPdfToFirebase,
} from "./ProductSettings/FileUpload"; // Import the function
import {
  AddProduct,
  getParentCategories,
  getBrands,
} from "../../services/products/Products"; // Import the Service

export default {
  components: {
    VBTooltip,
    BFormSelect,
    vSelect,
    useCurrencyInput,
    Cleave,
    BOverlay,
    BCardText,
    BTabs,
    BTab,
    BFormFile,
    ProductSettingPrice,
    quillEditor,
    VueUploadMultipleImage,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedFile: null,
      planPromo: false,
      locale: "fr",
      data: {
        selectedDate: new Date(),
      },
      selected: { title: "Square" },
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
      ],
      selectedCategory: [],
      imageOverlay: false,
      product: {
        images: [],
        categories: [],
        brands: [],
        attachmentInfo: null,
      },
      content: "",
      options: {},
      boxOne: "",
      boxTwo: "",
      loading: false,
      permalink: "",
      images: [],
      imagesUrl: [],
      regular_price: "",
      sale_price: "",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: false,
      valueWhenIsEmpty: "",
      options: {
        locale: "fr-TN",
        prefix: "DT",
        suffix: "",
        length: 11,
        max: 50000,
        precision: 3,
      },
      price: "",
      categories: [],
      brands: [],
      selectedCategories: [],
      categoriesLoader: false,
      BandsLoader: false,
    };
  },
  computed: {
    flattenedCategories() {
      const flattened = [];

      for (const category of this.categories) {
        // Add parent category (unselectable)
        flattened.push({
          ...category,
          formattedName: `* ${category.name}`,
          disabled: true,
        });

        if (category.subcategories && category.subcategories.length > 0) {
          for (const subcategory of category.subcategories) {
            // Add subcategory (selectable)
            flattened.push({
              ...subcategory,
              formattedName: `     \u00A0 - ${subcategory.name}`,
              disabled: false,
            });
          }
        }
      }

      return flattened;
    },
  },
  methods: {
    async uploadPdf() {
      const file = this.$refs.file.files[0];
      try {
        const uploadedUrls = await uploadPdfToFirebase(file);
        this.product.attachmentInfo = uploadedUrls;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        // Push the uploaded image URLs into the 'images' array
        this.product.images.push(...uploadedUrls);
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to add Product.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.ajoutProduit().then(() => {
                this.loading = false;
                this.makeToast("info");
              });
            }
          }
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("product added successfully click to view", {
        title: `Product ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async ajoutProduit() {
      if (this.sale_price) {
        this.product.sale_price = this.sale_price.toString();
      }
      if (this.regular_price) {
        this.product.regular_price = this.regular_price.toString();
      }

      this.permalink = await AddProduct(this.product);
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    formatDinar() {
      if (this.price != "") {
        var num = this.price;

        num = Number(num);

        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };

        var decimal = countDecimals(num);

        if (decimal < 2) {
          num = num.toFixed(2);
        }

        if (decimal > 2) {
          num = num.toFixed(decimal);
        }

        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }

        this.price = num;
      }
    },
    async refrechCategory() {
      this.categoriesLoader = true;
      this.categories = await getParentCategories();
      this.categoriesLoader = false;
    },
    async refrechBands() {
      this.BandsLoader = true;
      this.brands = await getBrands();
      this.BandsLoader = false;
    },
  },
  async created() {
    this.categoriesLoader = true;
    this.categories = await getParentCategories();
    this.categoriesLoader = false;
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
